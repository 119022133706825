<script setup lang="ts">
import type { Tables } from '~~/types/supabase'
import type { AtLeast } from '~~/types/utils'
import type { AvatarSize } from '#ui/types'
type Profile = Tables<'profiles'>

const props = withDefaults(
  defineProps<{
    user: AtLeast<Profile, 'avatar_url' | 'full_name'>
    size?: AvatarSize
  }>(),
  {
    size: 'md'
  }
)

const userInitials = computed(() => {
  return props.user.full_name
    ? props.user.full_name
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
    : ''
})
</script>
<template>
  <UAvatar
    :src="user.avatar_url ?? false"
    :alt="user.full_name ?? undefined"
    :size="size"
    :text="userInitials"
  />
</template>
